import { useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import { getMarketplaceList } from '@uptime/shared/graphql/marketplace';
import { ITEMS_AMOUNT_ON_MAP } from '@uptime/shared/constants';
import { marketplaceClient as controllerClient } from '@uptime/controller/src/apolloClientConfiguration';
import { marketplaceClient as sharedClient } from '@uptime/shared/graphql/apolloClientConfiguration';

export default (accountId, isOpen = false) => {
  const [serviceProviders, setServiceProviders] = useState([]);

  const handleFetch = async (obj) => {
    const doFetch = async (page) => {
      try {
        const client = isOpen ? sharedClient : controllerClient;
        const variables = accountId ? { userId: accountId } : {};
        const result = await client.query({
          query: getMarketplaceList,
          variables: {
            ...variables,
            page,
            itemsAmount: ITEMS_AMOUNT_ON_MAP,
            ...obj,
          },
          fetchPolicy: 'no-cache',
        });

        const {
          data: { list },
        } = result;

        return list;
      } catch {
        return { hits: [] };
      }
    };

    const marketplaceFetching = async () => {
      const startPage = 1;
      const { hits, pageCount } = await doFetch(startPage);
      setServiceProviders((state) => uniqBy([...state, ...hits], 'technicianUserId'));

      for await (const response of fetchGenerator(pageCount)) {
        setServiceProviders((state) => uniqBy([...state, ...response.hits], 'technicianUserId'));
      }
    };

    await marketplaceFetching();

    async function* fetchGenerator(pages) {
      const secondPage = 2;
      for (let i = secondPage; i <= pages; i++) {
        yield await doFetch(i);
      }
    }
  };

  return {
    serviceProviders,
    handleFetch,
  };
};
