import { useEffect } from 'react';

import { usePrevious } from '@uptime/shared/hooks';

interface Props {
  city?: string;
  onChange: () => void;
}

const ResetLocation = ({ city, onChange }: Props) => {
  const previousCity = usePrevious(city);

  useEffect(() => {
    if (!city && previousCity !== city) onChange();
  }, [city]); // eslint-disable-line

  return <></>;
};

export default ResetLocation;
