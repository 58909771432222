import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import styles from './styles';

const UserSkeleton = () => {
  const classes = styles();

  return (
    <Box className={classes.root} display="flex" flexDirection="column" p={3} data-testid="loader">
      <Skeleton variant="text" height={216} className={classes.bigAvatar} />
      <Box display="flex" flexDirection="column" alignItems="stretch" pb={3}>
        <Skeleton variant="text" height={36} className={classes.skeleton} />
        <Box pt={2} />
        <Skeleton variant="text" height={36} className={classes.skeleton} />
      </Box>
      <Skeleton variant="text" width={120} height={36} />
      <Box display="flex" alignItems="center" pt={2} pb={2.25}>
        <Skeleton variant="text" width={70} height={36} />
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="space-between" pt={2} pb={2}>
        <Box>
          <Skeleton variant="text" width={40} height={28} />
          <Skeleton variant="text" width={120} height={36} />
        </Box>
        <Box pl={2} />
        <Skeleton variant="circular" width={40} height={40} />
      </Box>
      <Skeleton variant="text" height={36} className={classes.skeleton} />
    </Box>
  );
};

export default UserSkeleton;
