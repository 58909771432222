import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import List from '@uptime/shared/components/List';
import { MARKETPLACE_LIST_HEADER } from '../../../constants/marketplace';

const TableSkeleton = () => {
  const header = MARKETPLACE_LIST_HEADER.map((item) => ({
    ...item,
    label: item.id !== 'actions' && <Skeleton variant="text" width={60} />,
  }));

  const rowsCount = Array.from(new Array(10).values());

  const displayObject = {
    xs: 'block',
    lg: 'flex',
  };

  const tableRows = rowsCount.map(() => ({
    photo: (
      <Box display="flex" alignItems="center">
        <Skeleton variant="circular" width={40} height={40} />
      </Box>
    ),
    technicianFullName: (
      <Box display="flex" alignItems="center">
        <Box pl={2}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={140} />
        </Box>
      </Box>
    ),
    technicianAddressLine: (
      <Box display={displayObject} justifyContent="center">
        <Skeleton variant="text" width={250} />
      </Box>
    ),
    technicianRateRange: (
      <Box display={displayObject} justifyContent="center">
        <Skeleton variant="text" width={100} />
      </Box>
    ),
    technicianCompanyType: (
      <Box display={displayObject} justifyContent="center">
        <Skeleton variant="text" width={100} />
      </Box>
    ),
    technicianCertificationCount: (
      <Box display={displayObject} justifyContent="center">
        <Skeleton variant="text" width={40} />
      </Box>
    ),
    actions: <Skeleton variant="text" width={26} height={42} />,
  }));

  return <List data={tableRows} headCells={header} outline="paddedBorder" />;
};

export default TableSkeleton;
