import Page from '@uptime/shared/components/Page';
import HubspotForm from 'react-hubspot-form';
import Box from '@mui/material/Box';
import WrapLink from '@uptime/shared/components/WrapLink';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CustomButton from '@uptime/shared/components/CustomButton';
import TextLogo from '@uptime/shared/components/TextLogo';
import useIsMobileView from '@uptime/shared/hooks/useIsMobileView';

const HubSpotConnection = (props) => {
  const {
    match: {
      params: { userId },
    },
  } = props;
  const isMobile = useIsMobileView();
  const customButton = (
    <Box flex={1} display="flex" flexDirection="row" pt={2} pb={2}>
      <Box mr={3.5} ml={-2}>
        {/* @ts-expect-error not yet typed JS file */}
        <CustomButton component="a" href="https://uptimehealth.com" variant="white">
          <TextLogo />
        </CustomButton>
      </Box>
      {/* @ts-expect-error not yet typed JS file */}
      <CustomButton component={WrapLink} to={`/app/marketplace/${userId}`} icon={<KeyboardArrowLeftIcon />}>
        {!isMobile && 'Go'} Back
      </CustomButton>
    </Box>
  );
  const space = {
    xs: 2,
    md: 22,
  };

  return (
    <Box pl={space} pr={space} maxWidth="100%">
      <Page leftNav={customButton}>
        <HubspotForm
          portalId="4169409"
          formId="76299ce1-f619-4814-a81a-c2a9737f85f0"
          loading={<div data-testid="loader">Loading...</div>}
        />
      </Page>
    </Box>
  );
};

export default HubSpotConnection;
