import React from 'react';
import ReactMapGL, { NavigationControl } from '@urbica/react-map-gl';
import * as PropTypes from 'prop-types';
import 'mapbox-gl/dist/mapbox-gl.css';

const VITE_MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoidmxhZHQtdXB0aW1laGVhbHRoIiwiYSI6ImNtMTBpenVpbTBocTgyeXF5ZGhvYmgzNGgifQ.wFRj5PK6Vh8e7CgaX2gKQw';

const Map = (props) => {
  const { isShowControls, children, mapRef, ...otherProps } = props;

  return (
    <ReactMapGL
      style={{
        width: '100%',
        height: '100%',
      }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      accessToken={VITE_MAPBOX_ACCESS_TOKEN}
      ref={mapRef}
      {...otherProps}
    >
      {children}
      {isShowControls && <NavigationControl showCompass showZoom position="bottom-left" />}
    </ReactMapGL>
  );
};

Map.propTypes = {
  isShowControls: PropTypes.bool,
  children: PropTypes.node,
  mapRef: PropTypes.object,
};

export default Map;
