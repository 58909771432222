import { useState, useEffect, forwardRef } from 'react';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import omit from 'lodash/omit';

import Page from '@uptime/shared/components/Page';
import FirstLoading from '@uptime/shared/screens/Marketplace/partials/FirstLoading';
import { getMarketplaceList } from '@uptime/shared/graphql/marketplace';
import MarketplaceList from '@uptime/shared/screens/Marketplace/partials/MarketplaceList';
import { marketplaceClient as client } from '@uptime/shared/graphql/apolloClientConfiguration';
import useMarketplaceTableRows from '@uptime/shared/screens/Marketplace/hooks/useMarketplaceTableRows';
import { getParamsFromSearch } from '@uptime/shared/utils/general';
import { getMutatedParamsForServiceProviders } from '@uptime/shared/utils/serviceProviders';
import useListDataChanging from '@uptime/shared/hooks/useListDataChanging';
import styles from '@uptime/shared/styles/MarketplaceStyles';
import TextLogo from '@uptime/shared/components/TextLogo';
import CustomButton from '@uptime/shared/components/CustomButton';
import ToggleMapButtons from '@uptime/shared/components/ToggleMapButtons';
import ResultOnMap from '@uptime/shared/components/MarketplaceResultsOnMap';
import useCenterDetect from '@uptime/shared/components/MarketplaceResultsOnMap/hooks/useCenterDetect';

import Filters from './Filters';

interface Props {
  location: {
    search: string;
  };
}

const Marketplace = ({ location: { search } }: Props) => {
  const classes = styles();

  const params = getParamsFromSearch(search);
  const { filters: filterParams } = params;

  const {
    loading,
    data: { list } = { list: {} },
    fetchMore,
  } = useQuery(getMarketplaceList, {
    variables: {
      userId: null,
      page: 1,
      ...params,
      filters: omit(filterParams, ['city']),
    },
    client,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isOpenMap, setIsOpenMap] = useState(false);

  useEffect(() => {
    loading === false && setIsFirstLoading(false);
  }, [loading]);

  const handleFetching = async (obj) => {
    setIsLoading(true);
    try {
      const { filters: filterParams } = obj;
      await fetchMore({
        variables: {
          userId: null,
          ...obj,
          filters: omit(filterParams, ['city']),
        },
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult,
      });
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  // @ts-expect-error untyped js file
  const changing = useListDataChanging({
    fetch: handleFetching,
    mutateParams: getMutatedParamsForServiceProviders,
  });

  const [{ filters }, eventHandlers] = changing;
  const { onFilter, onReset } = eventHandlers();

  const tableRows = useMarketplaceTableRows(list.hits);
  const center = useCenterDetect(list, filters);

  // @ts-expect-error untyped js file
  const WrapToggleMapButton = forwardRef((props, ref) => <ToggleMapButtons {...props} ref={ref} />);
  const handleChange = () => setIsOpenMap((state) => !state);

  if (isFirstLoading) return <FirstLoading />;

  const uptimeLink = (
    // @ts-expect-error untyped js file
    <CustomButton component="a" href="https://uptimehealth.com" variant="grey">
      <TextLogo />
    </CustomButton>
  );

  const isFiltersFilled = Object.values(filters).some((x) => x !== null);

  return (
    <Box className={classes.root}>
      <Page leftNav={uptimeLink}>
        <Filters
          userId={null}
          initialValues={filters}
          onSubmit={onFilter}
          additional={
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mt={5}>
              {/* @ts-expect-error untyped js file */}
              <WrapToggleMapButton onChange={handleChange} isOpenMap={isOpenMap} />
              <Button
                color="primary"
                disabled={!isFiltersFilled}
                variant="outlined"
                sx={{ minWidth: 80 }}
                onClick={onReset}
              >
                Clear Filters
              </Button>
            </Box>
          }
        />
        {isOpenMap ? (
          <Box pl={0} pr={4} flex={1}>
            {/* @ts-expect-error untyped js file */}
            <ResultOnMap center={center} location={filters?.location} isPublic />
          </Box>
        ) : (
          <Box pl={2} pr={2}>
            <MarketplaceList isLoading={isLoading} changing={changing} tableRows={tableRows} list={list} />
          </Box>
        )}
      </Page>
    </Box>
  );
};

export default Marketplace;
