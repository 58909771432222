import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import ChatIcon from '@mui/icons-material/Chat';
import { getName } from '@uptime/shared/utils/general';
import { EMPTY } from '@uptime/shared/components/constants';
import CertifiedIcon from '@uptime/shared/components/Icons/CertifiedIcon';
import UserAvatar from '@uptime/shared/components/UserAvatar';
import useFetchImageUrl from '@uptime/shared/hooks/useFetchImageUrl';
import { getProfileBusinessFileUrl, getProfileFileUrl } from '@uptime/shared/graphql/documents';
import accountStyles from '@uptime/shared/styles/AccountViewStyles';
import WrapLink from '@uptime/shared/components/WrapLink';
import MarketplaceViewUserButton from '@uptime/shared/components/MarketplaceViewUserButton';

import styles from './styles';
import UserSkeleton from './UserSkeleton';

const BUTTON_POSITION = 258;

interface User {
  firstName?: string;
  lastName?: string;
  businessName?: string;
  description?: string;
}

interface Props {
  user?: User;
  isLoading: boolean;
  isCertified: boolean;
  userId: number;
}

const UserSidebar = ({
  user: { businessName, description, ...profile } = {},
  userId,
  isCertified,
  isLoading,
}: Props) => {
  const classes = styles();
  const accountClasses = accountStyles();
  const theme = useTheme();
  const userName = getName(profile);
  const [isOpenBusinessInfo, setIsOpenBusinessInfo] = useState(false);
  const [isOpenUserSidebar, setIsOpenUserSidebar] = useState(false);

  const handleBusinessInfoToggling = () => setIsOpenBusinessInfo((state) => !state);

  const { url, fetching } = useFetchImageUrl({ userId }, getProfileFileUrl);

  const { url: businessUrl, fetching: businessUrlIsFetching } = useFetchImageUrl(
    { userId },
    getProfileBusinessFileUrl
  );

  if (isLoading) return <UserSkeleton />;

  const sideBarStyles = isOpenUserSidebar
    ? {
        left: 0,
      }
    : {};

  const userAvatar = {
    url,
    fetching,
    userName,
  };

  const size = {
    xs: 0,
    sm: 'auto',
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpenUserSidebar(false)}>
      <Box width={size} height={size}>
        <Box
          className={classes.root}
          style={sideBarStyles}
          flexDirection="column"
          overflow="auto"
          display="flex"
          p={3}
        >
          <UserAvatar
            // @ts-expect-error not yet typed JS file
            className={classes.bigAvatar}
            isLoading={fetching}
            name={userName}
            src={url}
            variant="rounded"
          />
          <Box display="flex" flexDirection="column" alignItems="stretch" pb={3}>
            {/* @ts-ignore */}
            <Button
              variant="contained"
              color="primary"
              component={WrapLink}
              to={`/app/connect/${userId}`}
              startIcon={<ChatIcon />}
            >
              Connect
            </Button>
            <Box pt={2} />
          </Box>
          <Typography variant="h2">{userName}</Typography>
          {isCertified && (
            <Box display="flex" alignItems="center" pt={3} pb={3}>
              <CertifiedIcon color={theme.palette.primary.main} />
              <Box pl={2} />
              <Typography>Certified</Typography>
            </Box>
          )}
          <Divider />
          <Box display="flex" alignItems="center" pt={3} pb={3}>
            <ListItemText className={accountClasses.textData} primary={businessName} secondary="Business" />
            <Box pl={2} />
            <UserAvatar isLoading={businessUrlIsFetching} name={businessName} src={businessUrl} />
          </Box>
          {description && isOpenBusinessInfo && (
            <Box mb={3} flex="1" position="relative" minHeight={100}>
              <Box className={classes.description}>{description || EMPTY}</Box>
            </Box>
          )}
          {description && (
            <Button onClick={handleBusinessInfoToggling} variant="outlined">
              {isOpenBusinessInfo ? 'Hide' : 'Read'} Business Info
            </Button>
          )}
        </Box>
        <MarketplaceViewUserButton
          bottomPosition="calc(100% - 150px)"
          leftPosition={isOpenUserSidebar ? BUTTON_POSITION : 0}
          onClick={() => setIsOpenUserSidebar((state) => !state)}
          isOpenUserSidebar={isOpenUserSidebar}
          userAvatar={userAvatar}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default UserSidebar;
