import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import NoResultsIcon from '../Icons/NoResultsIcon';

export default () => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <NoResultsIcon />
    <Typography variant="h3" mt={4} data-testid="noResultsPlaceholder">
      No Results Found
    </Typography>
  </Box>
);
