import { useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import Close from '@mui/icons-material/Close';

import AutoCompleteInput from '@uptime/shared/components/AutoComplete';
import useChangeCategory from '@uptime/shared/hooks/useChangeCategory';
import { TEXT_VALUES } from '@uptime/shared/constants/device';
import useTechnicianWizardQueries from '@uptime/shared/hooks/useTechnicianWizardQueries';
import SearchAddress from '@uptime/shared/components/SearchAddress';
import { RADIUS_RANGE } from '@uptime/shared/constants/marketplace';
import SliderInput from '@uptime/shared/components/Slider';
import styles from '@uptime/shared/styles/MarketplaceStyles';
import ResetLocation from './partials/ResetLocation';

interface Props {
  userId: string | null;
  onSubmit: (values: any) => void;
  initialValues: {
    equipmentCategoryId?: string;
  };
  additional?: React.ReactNode;
}

const Filters = ({ userId, onSubmit, initialValues, additional }: Props) => {
  const classes = styles();

  const {
    loading: serviceTypesAreLoading,
    categoriesAreLoading,
    serviceTypes,
    categories,
    // @ts-expect-error wrong type
  } = useTechnicianWizardQueries(userId);

  // @ts-expect-error wrong type
  const { doFetchMakes, makes, isLoading: makesAreLoading } = useChangeCategory(userId);

  useEffect(() => {
    const { equipmentCategoryId } = initialValues;

    equipmentCategoryId && doFetchMakes(equipmentCategoryId);
  }, [initialValues]); // eslint-disable-line

  const equipmentCategoryPlaceholder = categoriesAreLoading ? TEXT_VALUES.LOADING : 'Choose Category';
  const equipmentMakePlaceholder = makesAreLoading ? TEXT_VALUES.LOADING : 'Choose Make';
  const serviceTypePlaceholder = serviceTypesAreLoading ? TEXT_VALUES.LOADING : 'Choose Type of Service';

  const [{ value: min }, { value: max }] = RADIUS_RANGE;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form: { change } }) => {
        const onChangeCategory = async (event) => {
          const { value } = event || {};
          change('equipmentMakeId', undefined);
          value && (await doFetchMakes(value));
          await handleSubmit();
        };

        const onChangeLocation = async (locationContext, locationViewPort) => {
          const { longitude, latitude } = locationViewPort;
          const { label } = locationContext;
          const coordinates = {
            x: longitude,
            y: latitude,
          };
          change('city', label);
          change('location', {
            coordinates,
            range: 5,
          });
          await handleSubmit();
        };

        const onResetLocation =
          (isEmptyCity = true) =>
          async () => {
            !isEmptyCity && change('city', undefined);
            change('location', undefined);
            await handleSubmit();
          };

        return (
          <form className={classes.filters}>
            <Grid container>
              <Grid item xs={12} lg={5}>
                <Field type="hidden" name="location" component="input" />
                <FormSpy
                  subscription={{
                    initialValues: true,
                  }}
                >
                  {({ initialValues }) => (
                    <Field
                      name="equipmentCategoryId"
                      label="Category"
                      placeholder={equipmentCategoryPlaceholder}
                      // @ts-ignore
                      component={AutoCompleteInput}
                      onChangeCustom={onChangeCategory}
                      defaultValue={initialValues.equipmentCategoryId}
                      options={categories}
                      isDisabled={categoriesAreLoading}
                      isClearable
                    />
                  )}
                </FormSpy>
              </Grid>
              <Grid item xs={12} lg={5}>
                <FormSpy subscription={{ values: true, modified: true }}>
                  {({ values: { city } }) => (
                    <Box position="relative">
                      <ResetLocation city={city} onChange={onResetLocation()} />
                      <SearchAddress
                        placeholder="Choose Facility"
                        name="city"
                        label="Facility"
                        onSelectAddress={onChangeLocation}
                        params={{
                          types: 'place',
                          country: 'us',
                        }}
                      />
                      {city && (
                        <Box position="absolute" bottom={0} right={16}>
                          <IconButton onClick={onResetLocation(false)}>
                            <Close />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  )}
                </FormSpy>
              </Grid>
              <Grid item lg={2}>
                {additional}
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  name="serviceTypeId"
                  label="Type of Service"
                  placeholder={serviceTypePlaceholder}
                  onChangeCustom={handleSubmit}
                  // @ts-ignore
                  component={AutoCompleteInput}
                  options={serviceTypes}
                  isDisabled={serviceTypesAreLoading}
                  isClearable
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  name="equipmentMakeId"
                  label="Device Make"
                  placeholder={equipmentMakePlaceholder}
                  onChangeCustom={handleSubmit}
                  // @ts-ignore
                  component={AutoCompleteInput}
                  options={makes}
                  isDisabled={makesAreLoading}
                  isClearable
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormSpy subscription={{ values: true, modified: true }}>
                  {({ values: { city, location: { coordinates = {} } = {} } = {} }) => {
                    return (
                      <Field
                        name="location[range]"
                        label="Distance from Facility, miles"
                        onChangeCustom={handleSubmit}
                        component={SliderInput}
                        step={min}
                        min={min}
                        max={max}
                        marks={RADIUS_RANGE}
                        disabled={!Boolean(coordinates) || !Boolean(city)}
                      />
                    );
                  }}
                </FormSpy>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default Filters;
