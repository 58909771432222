import React from 'react';
import * as PropTypes from 'prop-types';

import ItemsOnMap from '@uptime/shared/components/ItemsOnMap';
import useMarketplaceTableRows from '@uptime/shared/screens/Marketplace/hooks/useMarketplaceTableRows';

import useRepairersOnPolygon from './hooks/useRepairersOnPolygon';
import MarkerView from './partials/MarkerView';

const ResultOnMap = (props) => {
  const {
    center,
    accountId,
    isPublic,
    location: { coordinates: { x: longitude, y: latitude } = {}, range } = {},
  } = props;

  const { serviceProviders, handleFetch } = useRepairersOnPolygon(accountId, isPublic);

  const tableRows = useMarketplaceTableRows(serviceProviders);

  return (
    <ItemsOnMap
      items={tableRows}
      onFetch={handleFetch}
      center={center}
      viewComponent={MarkerView}
      isDisabledSearch
      area={{
        latitude,
        longitude,
        range,
      }}
    />
  );
};

ResultOnMap.propTypes = {
  center: PropTypes.shape({
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
  }).isRequired,
  accountId: PropTypes.number,
  isPublic: PropTypes.bool,
  location: PropTypes.object,
};

export default ResultOnMap;
