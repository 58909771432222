import { useState } from 'react';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import className from 'classnames';

import CustomButton from '@uptime/shared/components/CustomButton';
import WrapLink from '@uptime/shared/components/WrapLink';
import { getTechnicianEquipments, getTechnicianServices } from '@uptime/shared/graphql/marketplace';
import { getCertifiedStatus } from '@uptime/shared/utils/marketplace';
import styles from '@uptime/shared/screens/Marketplace/View/styles';
import Page from '@uptime/shared/components/Page';
import { marketplaceClient } from '@uptime/shared/graphql/apolloClientConfiguration';
import Coverage from '@uptime/shared/screens/Marketplace/View/partials/Coverage';
import Equipments from '@uptime/shared/screens/Marketplace/View/partials/Equipments';
import Pricing from '@uptime/shared/screens/Marketplace/View/partials/Pricing';
import TabsSkeleton from '@uptime/shared/screens/Marketplace/View/partials/TabsSkeleton';
import Availability from '@uptime/shared/screens/Marketplace/View/partials/Availability';
import useServiceProviderInfo from '@uptime/shared/screens/Marketplace/hooks/useServiceProviderInfo';
import UserSidebar from './UserSidebar';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import TextLogo from '@uptime/shared/components/TextLogo';
import useIsMobileView from '@uptime/shared/hooks/useIsMobileView';

interface Props {
  match: {
    params: {
      userId: string;
    };
  };
  location?: {
    state?: {
      backLink?: string;
    };
  };
}

const View = (props: Props) => {
  const {
    match: {
      params: { userId: userIdString },
    },
  } = props;

  const classes = styles();

  const [activeTab, setActiveTab] = useState(0);

  const userId = Number(userIdString);

  const operations = {
    shouldSkipSP: true,
  };
  const { isLoading: serviceProviderIsLoading, profile } = useServiceProviderInfo(
    undefined,
    userId,
    operations
  );

  const { loading: equipmentsAreLoading, data: { equipments } = { equipments: { hits: [] } } } = useQuery(
    getTechnicianEquipments,
    {
      variables: {
        userId,
        page: 1,
        itemsAmount: 1000,
      },
      client: marketplaceClient,
    }
  );

  const { loading: servicesAreLoading, data: { services } = { services: [] } } = useQuery(
    getTechnicianServices,
    {
      variables: {
        userId,
      },
      client: marketplaceClient,
      fetchPolicy: 'no-cache',
    }
  );

  const isCertified = getCertifiedStatus(equipments.hits);

  const handleTabChanging = (event, tab) => setActiveTab(tab);

  const contentClasses = className(classes.content, { [classes.whiteBackground]: activeTab !== 0 });

  const isLoading = equipmentsAreLoading || serviceProviderIsLoading;
  const isMobile = useIsMobileView();

  const customButton = (
    <Box flex={1} display="flex" flexDirection="row" pl={3} pt={2} pb={2}>
      <Box mr={3.5}>
        {/* @ts-ignore */}
        <CustomButton component="a" href="https://uptimehealth.com" variant="white">
          <TextLogo />
        </CustomButton>
      </Box>
      {/* @ts-ignore */}
      <CustomButton component={WrapLink} to="/search" variant="grey" icon={<KeyboardArrowLeftIcon />}>
        Back{!isMobile && ' to Search Results'}
      </CustomButton>
    </Box>
  );

  return (
    <Box display="flex" flex="1" className={classes.clearSpace}>
      <Page leftNav={customButton}>
        <Box display="flex" position="relative" flex="1" maxWidth="100%" className={classes.root}>
          <UserSidebar isLoading={isLoading} user={profile} userId={userId} isCertified={isCertified} />
          <Box flex="1" display="flex" flexDirection="column" className={contentClasses}>
            {isLoading ? (
              <TabsSkeleton />
            ) : (
              <Box m={3} className={classes.tabs} zIndex={1} pl={1}>
                <Tabs
                  indicatorColor="primary"
                  scrollButtons="auto"
                  variant="scrollable"
                  onChange={handleTabChanging}
                  value={activeTab}
                >
                  <Tab label="Coverage Map" />
                  <Tab label="Availability" />
                  <Tab label="Devices" />
                  <Tab label="Pricing" />
                </Tabs>
              </Box>
            )}
            {activeTab === 0 && <Coverage userId={userId} />}
            {activeTab === 1 && <Availability services={services} isLoading={servicesAreLoading} />}
            {activeTab === 2 && <Equipments equipments={equipments} />}
            {activeTab === 3 && <Pricing services={services} isLoading={servicesAreLoading} />}
          </Box>
        </Box>
      </Page>
    </Box>
  );
};

export default View;
