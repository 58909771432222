import ReactDOM from 'react-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';

import muiTheme from '@uptime/shared/theme/muiTheme';

import './index.css';
import App from './App';

const AppContainer = () => (
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>
);

ReactDOM.render(<AppContainer />, document.getElementById('root'));
