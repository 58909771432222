// @ts-nocheck
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    maxWidth: 218,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      transition: 'all .5s',
      position: 'absolute',
      left: '-100%',
      top: 0,
      zIndex: 3,
      height: 'calc(100% - 48px)',
      boxShadow:
        '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
    },
  },
  bigAvatar: {
    marginBottom: theme.spacing(2),
    width: '216px !important',
    height: '216px !important',
    transform: 'none',
  },
  skeleton: {
    transform: 'none',
  },
  description: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
}));
