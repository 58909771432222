import React from 'react';
import * as PropTypes from 'prop-types';

import List from '@uptime/shared/components/List';
import { MARKETPLACE_LIST_HEADER } from '@uptime/shared/constants/marketplace';
import TableSkeleton from '@uptime/shared/screens/Marketplace/partials/TableSkeleton';
import NoResultsPlaceholder from '@uptime/shared/screens/Marketplace/partials/NoResultsPlaceholder';

const MarketplaceList = (props) => {
  const { list, tableRows = [], changing, isLoading } = props;

  const [{ sort }, eventHandlers] = changing;

  const { onChangePage, onSort } = eventHandlers(() => null);

  if (isLoading) return <TableSkeleton />;

  return (
    <>
      {tableRows.length ? (
        <List
          sort={sort}
          data={tableRows}
          pagination={list}
          onSort={onSort}
          headCells={MARKETPLACE_LIST_HEADER}
          onChangePage={onChangePage}
          customClass="marketplace-list"
          outline="paddedBorder"
        />
      ) : (
        <NoResultsPlaceholder />
      )}
    </>
  );
};

MarketplaceList.propTypes = {
  isLoading: PropTypes.bool,
  list: PropTypes.object,
  tableRows: PropTypes.array,
  changing: PropTypes.array.isRequired,
};

export default MarketplaceList;
