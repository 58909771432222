import { Route, Switch } from 'react-router-dom';

import Marketplace from './components/StartPage';
import './App.css';

import MarketPlaceList from './containers/MarketPlaceList';
import MarketplaceView from './components/View';
import HubSpotConnection from './containers/HubSpotConnection';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/search" component={MarketPlaceList} />
        <Route path="/app/connect/:userId" component={HubSpotConnection} />
        <Route path="/app/marketplace/:userId" component={MarketplaceView} />
        <Route path="/" component={Marketplace} />
      </Switch>
    </div>
  );
}

export default App;
