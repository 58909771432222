import React from 'react';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';

import { getAvatarPlaceholder } from '@uptime/shared/utils/images';
import styles from './styles';

const UserAvatar = (props) => {
  const classes = styles();
  const { isLoading, src, name, ...other } = props;

  const renderLoading = isLoading && <Box width="100%" height="100%" className={classes.loadingBox} />;
  const renderAvatarPlaceholder = !src && name && getAvatarPlaceholder(name);

  return (
    <Avatar alt={name} src={src} {...other} sx={{ width: 34, height: 34 }} data-testid="userAvatar">
      {renderLoading || renderAvatarPlaceholder}
    </Avatar>
  );
};

UserAvatar.propTypes = {
  isLoading: PropTypes.bool,
  src: PropTypes.string,
  name: PropTypes.string,
};

export default UserAvatar;
