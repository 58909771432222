import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Page from '@uptime/shared/components/Page';
import styles from '@uptime/shared/screens/Marketplace/StartForm/styles';
import { serializeSearchParams } from '@uptime/shared/utils';

import StartForm from './StartForm';

interface Props {
  history: {
    push: (path: string) => void;
  };
}

const StartPage = (props: Props) => {
  console.log(props);
  const classes = styles();

  const {
    history: { push },
  } = props;

  const onSubmit = (values) => {
    const serializedParams = serializeSearchParams({ filters: values, page: 1 });
    push(`/search?${serializedParams}`);
  };

  return (
    <Page className={classes.wrapper}>
      <Box pl={2} pr={2} pt={8}>
        <Box>
          <Typography className={classes.header} variant="h2">
            Explore the industry's first biomed technician marketplace.
          </Typography>
          <Typography className={classes.subTitle} variant="h4" color="white">
            Search healthcare's #1 marketplace for medical equipment support to find help in seconds.
          </Typography>
        </Box>
        <Box>
          <StartForm onSubmit={onSubmit} />
        </Box>
      </Box>
    </Page>
  );
};

export default StartPage;
